import React from 'react';
// import tw from 'twin.macro';
import Layout from '../components/Layout';
import LandingPage from '../landingPages';
import Seo from '../components/SEO';

// import Testing from './../components/Testing';
// interface ContainerProps {
//   hasBackground?: boolean;
// }
// const styles = {
//   // Move long class sets out of jsx to keep it scannable
//   container: ({ hasBackground }: ContainerProps) => [
//     tw`flex flex-col items-center justify-center h-screen`,
//     hasBackground && tw`bg-gradient-to-b from-electric to-ribbon`,
//   ],
// };

const IndexPage = () => (
  <Layout>
     <Seo title='CountMyCents | Affordable Tax filing and Bookkeeping Solution for Manitoba' />
    <LandingPage />
  </Layout>
);

export default IndexPage;
