import { chevronDown } from 'react-icons-kit/ionicons/chevronDown';

const FaqDataSource = {
  intro: 'FAQS',
  heading: 'You have Questions ?',
  description:
    'And we have got answers to all of them. CountMyCents is always there for your help.',
  questionToggleicon: chevronDown,
  children: [
    {
      name: 'faq1',
      question: 'How CountMyCents is different than filing taxes online?',
      answer:
        'Tax is very complex. If CRA audit, We will help you understand why and guide you through each step.',
    },
    {
      name: 'faq2',
      question: 'Is CountMyCents tax Expert is a real person?',
      answer: 'Yes, your tax Expert is a fully-trained tax professional who works in our office.',
    },
    {
      name: 'faq3',
      question: 'Which area is CountMyCents serving?',
      answer:
        'We are located in southern Manitoba. We serve online only in Manitoba. Other services only in Steinbach.',
    },
    {
      name: 'faq4',
      question: 'How can I reach you for support?',
      answer:
        'You can communicate with your assigned tax expert through e-mail, phone or video call.',
    },
    {
      name: 'faq5',
      question: 'What happens if I get an audit?',
      answer: 'If CRA audit, We will help you understand why and guide you through each step.',
    },
    {
      name: 'faq6',
      question: 'How much is your fee?',
      answer:
        'We have the best price in the market. We beat the price. For more information, check out our pricing section.',
    },
    {
      name: 'faq7',
      question:
        'What should I do if an email or call that seems to be from the CRA or CountMyCents asks me to provide personal or sensitive information? ',
      answer:
        'Fraudulent emails or phone calls are very common these days. Never click on any links and pick up any phone call if you are in doubt. DO NOT enter and give personal information of any kind.',
    },
  ],
};
export default FaqDataSource;
