import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import { SectionHeading, SectionSubheading, SectionDescription } from '../misc/Typography';
import { Container, ThreeColumnContainer } from '../misc/Layouts';
import Icon from 'react-icons-kit';

import FeaturesDataSource from '../../common/data/features';

// import SvgDecoratorBlob3 from './../../images/svg/svg-decorator-blob-3.svg';

const Subheading = tw(SectionSubheading)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const IconWrapper = tw`
    w-16 h-16 text-primary-500
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`text-center rounded-full p-5 flex-shrink-0`}
    .iconContainer {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-600 leading-loose`}
  }
`;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

const Features = () => {
  const { heading, subheading, description, children } = FeaturesDataSource;
  // const defaultCards = [

  // ];

  // if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {children.map((card) => (
          <Column key={card.id}>
            <Card>
              <span className="imageContainer">
                {/* <img src={card.imageSrc || defaultCardImage} alt={card.alt} /> */}
                <span className="iconContainer">
                  <Icon icon={card.icon} size="34" style={IconWrapper} />
                </span>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || 'Fully Secure'}</span>
                <p className="description">
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
export default Features;
