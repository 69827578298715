import React from 'react';
// import tw from 'twin.macro';
import Hero from './../components/hero/TwoColumnWithInput';
import Features from './../components/features';
import Pricing from './../components/pricing';
import Faqs from './../components/faqs';

// import Blogs from './../components/blogs';
// import Reviews from '../components/testimonials';
// import NewsLetter from '../components/form/Newsletter.js';

// import Steps from '../components/steps';

// const EmailNewsletterIcon = tw(TestSvgBase)``;
// import Testing from './../components/Testing';
// interface ContainerProps {
//   hasBackground?: boolean;
// }
// const styles = {
//   // Move long class sets out of jsx to keep it scannable
//   container: ({ hasBackground }: ContainerProps) => [
//     tw`flex flex-col items-center justify-center h-screen`,
//     hasBackground && tw`bg-gradient-to-b from-electric to-ribbon`,
//   ],
// };

const LandingPage = () => (
  <>
    <Hero />
    {/* <Steps /> */}
    <Features />
    <Pricing />
    <Faqs />
    {/* <Blogs /> */}
    {/* <Reviews /> */}
    {/* <NewsLetter /> */}
  </>
);

export default LandingPage;
