import { shield } from 'react-icons-kit/fa/shield';
import { home } from 'react-icons-kit/fa/home';
import { ic_contactless } from 'react-icons-kit/md/ic_contactless';
import { money } from 'react-icons-kit/fa/money';
import { clockO } from 'react-icons-kit/fa/clockO';
import { support } from 'react-icons-kit/fa/support';

const FeaturesDataSource = {
  cards: null,
  heading: 'Amazing Features',
  subheading: 'Features',
  description: 'CountMyCents is Proudly located in Southern Manitoba.',
  children: [
    {
      id: 'feature01',
      icon: shield,
      title: 'Secure',
      description: 'Your Private Information is Secure at each step.',
      alt: 'Secure',
    },
    {
      id: 'feature02',
      icon: home,
      title: 'Comfort of home',
      description: 'File Tax returns from the comfort of your home.',
      alt: 'Comfort',
    },
    {
      id: 'feature03',
      icon: ic_contactless,
      title: 'Contactless',
      description: 'Why leave your home in Covid. File tax from your home.',
      alt: 'Contactless',
    },
    {
      id: 'feature04',
      icon: money,
      title: 'Competitive fee',
      description: 'We have a Competitive fee in the Market. We beat the price.',
      alt: 'competitive fee',
    },
    {
      id: 'feature05',
      icon: clockO,
      title: 'Time-Saving',
      description: 'Wait no more in the tax filing office.',
      alt: 'Time',
    },
    {
      id: 'feature06',
      icon: support,
      title: 'Audit Support',
      description: 'If CRA audit, We’ll help you understand why and guide you through each step.',
      alt: 'Audit Support',
    },
  ],
};
export default FeaturesDataSource;
