import React from 'react';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import {
  Container,
  TwoColumnContainer,
  LeftColumnContainer,
  RightColumnContainer,
} from '../misc/Layouts';
import GetStartedForm from '../form/GetStarted';

import DesignIllustration from '../../images/icons/design-illustration-hero.svg';

export const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

export default () => {
  return (
    <>
      <Container>
        <TwoColumnContainer>
          <LeftColumnContainer>
            <Heading>
              Hire the best <div tw="text-primary-500">Virtual Tax Expert.</div>
            </Heading>
            <Paragraph>
              We have now launched operations in Manitoba for virtual tax filing. We also accept
              pickup service in Steinbach only.
            </Paragraph>
            <GetStartedForm
              hubspotFormId="313f5e5a-96a3-4d64-9556-70fddf852302"
              hubspotPortalId="21292637"
              submitButtonText="Get Started"
              buttonAction="/get-started"
              failureMessage="Ensure you're using a valid email address & try again. "
            />
          </LeftColumnContainer>
          <RightColumnContainer>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumnContainer>
        </TwoColumnContainer>
      </Container>
    </>
  );
};
