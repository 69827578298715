import React, { useState } from 'react';

import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import PropTypes from 'prop-types';

import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';
import endpoints from '../../util/endpoints';
import { isEmail } from '../../util/utils';

import { navigate } from 'gatsby'; //import navigate from gatsby
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const GetStarted = (props) => {
  const { hubspotPortalId, hubspotFormId, submitButtonText, buttonAction, failureMessage } = props;

  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(true);

  const validate = (emailid) => {
    return isEmail.test(String(emailid).toLowerCase());
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    if (validate(event.target.value)) {
      setErrorMessage('');
    }
  };

  const handleError = () => {
    setError(true);
    setErrorMessage(failureMessage);
  };
  const handleSuccess = () => {
    navigate(buttonAction); //navigate to edit page
  };

  const handleHubsot = (data) => {
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `${endpoints.contact}/${hubspotPortalId}/${hubspotFormId}`;
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: data,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
    };

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then((res) => res.json())
      .then(() => handleSuccess())
      .catch((err) => handleError(err));
  };
  const handleSubmitButton = () => {
    if (validate(email)) {
      handleHubsot(email);

    } else {
      handleError();
    }
  };
  return (
    <>
      <Actions>
        <input
          type="email"
          placeholder="Your E-mail Address"
          value={email}
          onChange={handleChange}
          required="required"
        />

        <button onClick={() => handleSubmitButton()}>{submitButtonText}</button>
      </Actions>
      <div tw="text-red-300">{error && errorMessage}</div>
    </>
  );
};
export default GetStarted;

GetStarted.propTypes = {
  hubspotFormId: PropTypes.string.isRequired,
  hubspotPortalId: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.string.isRequired,
  // successMessage: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
};
