import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Icon from 'react-icons-kit';

import { SectionIntroduction, SectionDescription, SectionHeading } from '../misc/Typography';
import { Container, ContentWithPaddingXl, SingleColumnContainer } from '../misc/Layouts';

import { chevronDown } from 'react-icons-kit/ionicons/chevronDown';
// import SvgDecoratorBlob1 from './../../images/svg/svg-decorator-blob-7.svg';
// import SvgDecoratorBlob2 from './../../images/svg/svg-decorator-blob-8.svg';
import FaqDataSource from '../../common/data/faqs';

const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;

const Faqs = () => {
  const { intro, heading, description, children, questionToggleicon } = FaqDataSource;
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="faqs">
      <ContentWithPaddingXl>
        <SingleColumnContainer>
          <HeaderContent>
            <SectionIntroduction>{intro}</SectionIntroduction>
            <SectionHeading>{heading}</SectionHeading>
            <SectionDescription>{description}</SectionDescription>
          </HeaderContent>
          <FAQSContainer>
            {children.map((faq) => (
              <FAQ
                key={faq.name}
                onClick={() => {
                  toggleQuestion(faq.name);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === faq.name ? 'open' : 'collapsed'}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <Icon icon={questionToggleicon || chevronDown} />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: 'auto', marginTop: '16px' },
                    collapsed: { opacity: 0, height: 0, marginTop: '0px' },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === faq.name ? 'open' : 'collapsed'}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </SingleColumnContainer>
      </ContentWithPaddingXl>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};

export default Faqs;
